import axios from "axios";
import { Headers } from "./generic";

export class Admin {
  static async reindex(
    entityType: "chapter" | "title" | "user" | "organization",
    id: string,
    auth: string,
  ) {
    return await axios.post(
      "/search/reindex",
      {
        entityType,
        entityId: id,
      },
      {
        responseType: "json",
        headers: Headers.Bearer(auth),
      },
    );
  }
}
